<!-- 
author:张洪志
description: 认购指南
 -->
<template>
  <section class="buy-guide" v-if="show">
    <div class="vw">
      <span class="btn ico-x" @click="toggleShow(false)"></span>
      <div class="title">认购指南</div>
      <div class="sub-title">
        <span class="ico ico-block"></span>
        <span>新发基金认购流程</span>
      </div>
      <div>
        <img src="../../../../static/img/fund-market/new-fund/buy-guide_r1_c1.png" />
        <p class="flex-between">
          <small>不超过3个月</small>
          <small style="margin-left: 20px;">一般为3-7天</small>
          <small>无法申赎一般不超过3个月</small>
          <small>以基金公司公告为准</small>
        </p>
      </div>
      <div class="sub-title">
        <span class="ico ico-block"></span>
        <span>选购新发基金应该考虑哪些问题?</span>
      </div>
      <div>
        <img src="../../../../static/img/fund-market/new-fund/buy-guide_r3_c1.png" />
      </div>
      <div class="sub-title">
        <span class="ico ico-block"></span>
        <span>认购小贴士</span>
      </div>
      <div class="txt">
        新基金的成长能力和成长空间相对老基金更大一些。新基金没有历史负担，更加灵活，而老基金受制于规模不便及时调仓，在市场出现转折时新基金更能把握住机遇。
      </div>
      <div class="sub-title txt">
        <p>基金投资有风险，本公司（众惠基金）提醒投资人基金投资的“买者自负”原则，</p>
        <p>
          在做出投资决策后，基金运营状况与基金净值变化引致的投资风险，由投资人自行负担。
        </p>
        <p>
          敬请投资人在购买基金前认真考虑、谨慎决策。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'BuyGuide',
    data() {
      return {
        show: false
      }
    },
    methods: {
      toggleShow(show) {
        this.show = show
      }
    },
  }
</script>

<style lang="less" scoped>
  .buy-guide{ position: absolute; top:0; left: 0; width:100%; height:100%; min-height: 820px; padding:50px 0;background: rgba(255,255,255,0.5); color:#999; z-index:3;}
  .vw{width:850px; padding:50px 75px; box-shadow: 0 0 10px #ddd; position: relative; background: #fff url(../../../../static/img/fund-market/new-fund/buy-guide_r5_c2.png) no-repeat right bottom;}
  .ico-x{ position: absolute; top:50px; right:40px; font-size: 24px;}
  .title{font:bold 20px "microsoft yahei"; color:#000;}
  .sub-title{ margin:35px 0;}
  .txt{ line-height: 2em;}
</style>
