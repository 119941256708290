<!-- 
author:张洪志
description: 新发基金详情
 -->
<template>
<section class="fund-detail">
    <div class="page-nav vw">
        <span>当前位置:</span>
        <router-link to="/">众惠首页</router-link>
        <span>&gt;</span>
        <router-link :to="{name: 'FundMarket'}">基金超市</router-link>
        <span>&gt;</span>
        <span>{{fundBaseInfo.fundName}}</span>
    </div>
    <div class="fund-base flex-between vw">
        <div class="base-left">
            <div class="title-box">
                <span class="title">{{fundBaseInfo.fundName}}</span>
                <span class="tag">{{formatter(fundBaseInfo.fundStatus,sys_fund_status)}}</span>
            </div>
            <div class="code orange">基金代码 <big>{{fundCode}}</big></div>
            <div class="type">
                <span class="btn-orange-light">{{ formatter( fundBaseInfo.ofundType,sys_fund_type)}} </span>
                <span class="btn-orange-2">
                    <span class="ico-eye-open"></span>
                    <span> {{ formatter( fundBaseInfo.ofundRisklevel,sys_fund_risklevel)}}</span>
                </span>
            </div>
            <div class="surplus-time" v-if="surplusTime <= 0">
                <div class="time gray">认购结束</div>
            </div>
            <div class="surplus-time" v-else-if="surplusTime <= 48*60*60">
                <div class="time">
                    <span>{{surplusData.hours[0]}}</span>
                    <span>{{surplusData.hours[1]}}</span>
                    <i>:</i>
                    <span>{{surplusData.minute[0]}}</span>
                    <span>{{surplusData.minute[1]}}</span>
                    <i>:</i>
                    <span>{{surplusData.second[0]}}</span>
                    <span>{{surplusData.second[1]}}</span>
                </div>
            </div>
            <div class="surplus-time" v-else>
                <div class="time">
                    {{(fundBaseInfo.setupDate||'').$formatDate()}}
                    <small class="gray">截止</small>
                </div>
            </div>
            <div class="notice btn-orange-light">认购期仅供参考，以基金公司公告为准。为避免基金认购提前结束，建议及时关注募集结束时间。
            </div>
        </div>
        <div class="base-right">
            <div class="flex-between" style="align-items: flex-end;">
                <p>
                    <span style="font-size: 17px;">投资指南</span>
                    <span class="ico-money orange" style="margin: 0 5px;"></span>
                    <span>民生银行全程资金监管</span>
                </p>
                <p>
                    <small class="gray btn" @click="showInvestGuide">查看指南 </small>
                    <span>&gt;</span>
                </p>
            </div>
            <div style="padding:30px 0;">
                <p style="padding-left: 20px;">
                    <img src="@/static/img/fund-market/detail_2.png" />
                </p>
            </div>
            <div class="buy-box">
                <div class="flex-between buy-box-up">
                    <div style="border-right: 1px solid #ddd;">
                        <p class="red" v-if="discountRate"><big>{{discountRate.toFixed(2).$trimZero()}}%</big></p>
                        <p class="gray" style="margin-bottom: 1em; font-size: 20px;" v-else>免手续费</p>
                        <p v-if="rate"><s>{{rate.toFixed(2).$trimZero()}}%</s></p>
                        <p class="gray">认购费率</p>
                    </div>
                    <div class="orange">
                        <p><big>{{subStartAmt}}</big>元</p>
                        <p>&nbsp;</p>
                        <p>起购金额</p>
                    </div>
                </div>
                <div class="flex-evenly buy-box-down">
                    <a :class="['btn btn-orange', {'disable stop-buy': buyStatus === '0'}]" @click="buyFund()">认购</a>
                </div>
            </div>
            <div class="notice small gray flex-between">
                <span class="ico-right orange-light" style="font-size: 1.6em;"></span>
                <p style="margin-left: 6px;">
                    确认即表示已仔细阅读并同意
                    <a class="orange">《产品概要》</a>、
                    <a class="orange">《基金合同》</a>、
                    <a class="orange">《招募说明书》</a>、
                    <a class="orange">《风险揭示书》</a>和
                    <a class="orange">《客户维护费揭示书》</a>
                    等
                </p>
            </div>
        </div>
    </div>
    <div class="fund-info vw">
        <div class="tab flex-between">
            <span :class="{'current':detailIndex===1}" @click="detailIndex=1">基金概况</span>
            <span :class="{'current':detailIndex===2}" @click="detailIndex=2">基金经理</span>
            <span :class="{'current':detailIndex===3}" @click="detailIndex=3">基金公司</span>
            <span :class="{'current':detailIndex===4}" @click="detailIndex=4">基金持仓</span>
            <span :class="{'current':detailIndex===5}" @click="detailIndex=5">基金公告</span>
            <span :class="{'current':detailIndex===6}" @click="detailIndex=6">交易费率</span>
            <span :class="{'current':detailIndex===7}" @click="detailIndex=7">基金分红</span>
            <span :class="{'current':detailIndex===8}" @click="detailIndex=8">财务指标</span>
        </div>
        <div class="pannel">
            <keep-alive>
                <component :is="detailView" :fundCode="fundCode"></component>
            </keep-alive>
            <div class="notice btn-orange-light big-txt small">
                郑重声明:
                <br>
                以上信息(包括但不限于文字、视频、音频、数据及图表)均基于公开信息采集，相关信息并未经过本公司证实，本公司不保证该信息全部或者部分内容的准确性、真实性、完整性，不构成本公司任何推荐或保证，基金具体信息以管理人相关公告为准，投资者投资前需仔细阅读《基金合同》、《招募说明书》等法律文件，了解产品收益与风险特征，过往业绩不预示其未来表现，市场有风险，投资需谨慎。数据来源：恒生聚元数据。
            </div>
        </div>
    </div>
    <buy-guide ref="buyGuide"></buy-guide>
    <DinDetails :showmodel="showBuyConfirm" @cancels="showBuyConfirm=false" @showdelte="showBuyConfirm=false" @confirms="buyConfirm">
      {{buyConfirmTxt}}
    </DinDetails>
</section>
</template>

<script>
import DinDetails from '@/components/Models.vue'
import BuyGuide from '@/views/fund-market/new-fund/components/BuyGuide.vue'
import FundDetail_1 from '@/views/fund-market/components/FundDetail-1.vue'
import FundDetail_2 from '@/views/fund-market/components/FundDetail-2.vue'
import FundDetail_3 from '@/views/fund-market/components/FundDetail-3.vue'
import FundDetail_4 from '@/views/fund-market/components/FundDetail-4.vue'
import FundDetail_5 from '@/views/fund-market/components/FundDetail-5.vue'
import FundDetail_6 from '@/views/fund-market/components/FundDetail-6.vue'
import FundDetail_7 from '@/views/fund-market/components/FundDetail-7.vue'
import FundDetail_8 from '@/views/fund-market/components/FundDetail-8.vue'
import {
    fundBaseInfo,fundSurvey
} from '@/api/fundDetail'
import {
    selectDictLabel
} from "@/utils/dicts";

import {fundRate2} from '@/api/fund.js'
export default {
    name: 'NewFundDetail',
    components: {
        FundDetail_1,
        FundDetail_2,
        FundDetail_3,
        FundDetail_4,
        FundDetail_5,
        FundDetail_6,
        FundDetail_7,
        FundDetail_8,
        BuyGuide,
        DinDetails
    },
    props: {
        fundCode: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            tradeRuleVisible: false,
            detailIndex: 1,
            surplusTime: 0,
            fundBaseInfo: {}, //基金基本信息
            surplusData: {
                hours: '00',
                minute: '00',
                second: '00'
            },
            fundSetupDate: "",
            sys_fund_risklevel: [],
            sys_fund_status: [],
            sys_fund_type: [],
            
            /** 费率信息 */
            discountRate: 0,
            rate: 0,
            subStartAmt: '0.01',
            
            showBuyConfirm: false,
            buyConfirmTxt: '',
            buyConfirmStep: 0,
      
            buyStatus:'1'
        }
    },
    computed: {
        detailView() {
            switch (this.detailIndex) {
                case 2:
                    return FundDetail_2
                case 3:
                    return FundDetail_3
                case 4:
                    return FundDetail_4
                case 5:
                    return FundDetail_5
                case 6:
                    return FundDetail_6
                case 7:
                    return FundDetail_7
                case 8:
                    return FundDetail_8
                default:
                    return FundDetail_1
            }
        },
    },
    created() {
        this.initDicts()
        this.getFundDetail()
        this.fundRate()
    },
    methods: {
        // 初始化自典
        initDicts() {
            this.getDicts('sys_fund_type').then(r1 => {
                this.sys_fund_type = r1.data;
            });
            this.getDicts('sys_fund_risklevel').then(r1 => {
                this.sys_fund_risklevel = r1.data;
            });
            this.getDicts('sys_fund_status').then(r1 => {
                this.sys_fund_status = r1.data;
            });
        },
        // 返显
        formatter(val, options) {
            return selectDictLabel(val, options);
        },
        // 获取基本信息
        getFundDetail() {
            let param = {
                fundCode: this.fundCode
            };
            fundBaseInfo(param).then(res => {
                const {
                    code,
                    data
                } = res;
                if (code == 200) {
                    this.fundBaseInfo = data;
                    this.surplusTime = parseInt(Number(data.surplusTime) / 1000);
                    this.surplusTimeInterval();
                }
            })
            fundSurvey(param).then(result => {
              this.buyStatus = result.data.fundBase.buyStatus
            })
        },
        /** 显示投资指南 */
        showInvestGuide() {
            this.$refs.buyGuide.toggleShow(true)
        },
        /** 认购倒计时 */
        surplusTimeInterval() {
            let surplusTime = this.surplusTime;
            if (surplusTime <= 0) {
                return
            }
            let interval = setInterval(() => {
                surplusTime--
                this.surplusTime = surplusTime
                if (surplusTime === 0) {
                    clearInterval(interval)
                    return
                }
                if (surplusTime > 0 && surplusTime <= 48 * 60 * 60) {
                    let hours = parseInt(surplusTime / 60 / 60)
                    this.surplusData.hours = hours < 10 ? '0' + hours : hours.toString()
                    let minute = parseInt((surplusTime - hours * 60 * 60) / 60)
                    this.surplusData.minute = minute < 10 ? '0' + minute : minute.toString()
                    let second = parseInt(surplusTime % 60)
                    this.surplusData.second = second < 10 ? '0' + second : second.toString()
                }
            }, 1000)
        },
        
        /** 获取费率 */
        fundRate() {
          fundRate2(this.fundCode,'2').then(result => {
            let data = result.data
            if(data) {
              //this.buyConfirmDate = data.buyConfirmDate
              data.rate = parseFloat(data.rate || 0)
              data.discountRate = parseFloat(data.discountRate || 100) / 100
              this.discountRate = data.rate * data.discountRate
              this.rate = data.rate
              if(data.discountRate === 1) {
                this.discountRate = this.rate
                this.rate = 0
              }
              this.subStartAmt = data.subStartAmt||0.01
            }
          })
        },
        /** 购买基金 */
        buyFund() {
          if(this.buyStatus === '0') {
            return;
          }
          this.buyConfirmStep = 0
          let token = this.$store.getters.token;
          let _userInfo = localStorage.getItem("customerInfo");
          if (!token || !_userInfo) {
            this.buyConfirmTxt = '请登录!'
            this.buyConfirmStep = 1
          }else {
            let userInfo = JSON.parse(_userInfo);
            if (!userInfo.acctFlag || userInfo.acctFlag == "0") {
              this.buyConfirmTxt = '请先开户!'
              this.buyConfirmStep = 2
            }else if (!userInfo.addDataFlag || userInfo.addDataFlag == "0") {
              this.buyConfirmTxt = '请补充资料!'
              this.buyConfirmStep = 3
            }else if (!userInfo.tradePwdFlag || userInfo.tradePwdFlag == "0") {
              this.buyConfirmTxt = '请设置交易密码!'
              this.buyConfirmStep = 4
            }else if (!userInfo.riskFlag || userInfo.riskFlag == "0") {
              this.buyConfirmTxt = '请先完成风险测评!'
              this.buyConfirmStep = 5
            }else if(userInfo.riskLevel < this.fundBaseInfo.ofundRisklevel){
              this.buyConfirmTxt = '当前产品风险等级高于您的风险测评等级，确认购买？'
              this.buyConfirmStep = 6
            }
          }
          if(!this.buyConfirmStep) {
            this.buyConfirm()
            return
          }      
          this.showBuyConfirm = true
        },
        /** 购买基金确认 */
        buyConfirm() {
          this.showBuyConfirm = false
          if(this.buyConfirmStep === 1) { 
            this.$router.push({ name: "Login" })
            return
          }
          if(this.buyConfirmStep === 2) { 
            this.$router.push({ name: "OpenAccount" });
            return
          }
          if(this.buyConfirmStep === 3) { 
            this.$router.push({ name: "PersonalDataSupply" });
            return
          }
          if(this.buyConfirmStep === 4) { 
            this.$router.push({ name: "OpenAccount" });
            return
          }
          if(this.buyConfirmStep === 5) { 
            this.$router.push({ name: "RiskAssessment" });
            return
          }
          this.$router.push({name:'ProcessPurchase',params:{fundCode:this.fundCode,newfund:1}})
        }
    }
}
</script>

<style lang="less" scoped>
@gray: #999;
@gray-bg: #F3F4F6;
@orange: #CE9B63;
@green: #01B834;
@red: #D43F3F;
@blue: #00aaff;

.gray {
    color: @gray;
}

.gray-bg {
    background: @gray-bg;
}

.orange {
    color: @orange;
}

.orange-light {
    color: #f8e4c4;
}

.green {
    color: @green;
}

.red {
    color: @red;
}

.blue {
    color: @blue;
}

.btn-orange {
    background: #F1CDA8;
    color: #BF8A4E;
    border-radius: 3px;
    text-align: center;
}

.btn-orange-light {
    background: #FFF5EA;
    color: #C59660;
    border-radius: 3px;
}

.btn-orange-2 {
    background: #F6D7B4;
    background-image: linear-gradient(90deg, #F6D7B4, #ECC39B);
    color: #fff;
    border-radius: 3px;
}

.btn-orange-plain {
    border: 1px solid #F1CDA8;
    color: #BF8A4E;
    border-radius: 3px;
    text-align: center;
}

.small {
    font-size: 12px;
}

.fund-detail {
    .gray-bg();
}

.page-nav {
    font-size: 15px;
    line-height: 3.5em;
    background: none;

    &>* {
        .gray();
        margin-right: 0.5em;
    }
}

.fund-base {
    padding: 40px 0 0px 0;
    justify-content: center;

    .base-left {
        width: 750px;
        margin-right: 30px;

        .title-box {
            .title {
                font: bold 22px "microsoft yahei";
            }

            .tag {
                .btn-orange();
                padding: 4px 8px;
                margin-left: 1.2em;
            }
        }

        .code {
            line-height: 3.6em;
        }

        .type>span {
            padding: 4px 8px;
            margin-right: 12px;
            font-size: 12px;
        }

        .surplus-time {
            margin-top: 40px;

            .time {
                color: #f00;
                font: 36px/60px "microsoft yahei";

                span {
                    background: #F9DEB5;
                    background-image: linear-gradient(110deg, #F5CF93 20%, #FFF8EC, #FCECD3 80%);
                    padding: 0px 5px;
                    margin-right: 2px;
                }

                i {
                    font-style: normal;
                    padding: 0px 8px;
                }
            }
        }

        .notice {
            padding: 1em;
            margin-top: 40px;
        }
    }

    .base-right {
        width: 360px;

        .buy-box-up {
            text-align: center;

            &>div {
                width: 49%;
            }

            big {
                font-size: 30px;
            }
        }

        .buy-box-down {
            margin-top: 20px;

            .btn {
                width: 90%;
                line-height: 2.2em;
                font-size: 1.3em;
            }
        }

        .notice {
            align-items: center;
            padding: 25px 0;
        }
    }
}

.fund-info {
    padding-top: 30px;

    .tab {
        font: 15px "microsoft yahei";
        .gray();
        justify-content: space-around;
        line-height: 2.8em;
        border-bottom: 1px solid #eee;

        &>span {
            cursor: pointer;
        }

        &>.current {
            font: bold 16px/2.8em "microsoft yahei";
            .orange();
            border-bottom: 3px solid @orange;
            margin-bottom: -1px;
        }
    }

    .pannel {
        padding: 0 30px 30px 30px;

        .title {
            font-size: 20px;
            line-height: 3em;
            margin-top: 10px;

            .ico {
                margin: 0 5px;
            }
        }

        .table2 {
            line-height: 3em;
            font-size: 15px;
            border-top: 1px solid #eee;

            dl {
                border-bottom: 1px solid #eee;
            }

            dt {
                .gray-bg();
                width: 6em;
                padding: 0 1em;
            }

            dd {
                width: 460px;
                padding-left: 1em;
            }
        }

        .big-txt {
            line-height: 2em;
            text-indent: 2em;
        }

        .notice {
            text-indent: 0;
            padding: 1em;
            margin-top: 1em;
            ;
        }
    }
}
</style>
